<template>
  <p class="generic-header"> {{ title }} </p>
</template>


<script>
export default {
  props: [
    'title',
  ]
}
</script>
<template>
  <div class="navbar-container">
    <b-navbar>
      <b-navbar-nav :align="align">
          <b-nav-item v-if="showBackButton" @click="$router.go(-1)" class="nav-item-back">
            <!-- shift vertically by 1 unit because otherwise it seems as if the nav-items
            aren't aligned properly -->
            <b-icon class="back-icon" icon="arrow-counterclockwise" shift-v="-1" font-scale="2"></b-icon>
          </b-nav-item>

          <b-nav-item v-if="showHomeButton" to="/" class="nav-item-home">
            <b-icon class="home-icon" icon="house-door" font-scale="2"></b-icon>
          </b-nav-item>

          <b-nav-item v-if="showSettingsButton" to="/settings" class="nav-item-settings">
            <b-icon class="settings-icon" icon="gear" font-scale="2"></b-icon>
          </b-nav-item>

      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>

export default {
  props: {
    showBackButton:  {
      type: Boolean,
      default: true,
    },
    showHomeButton:  {
      type: Boolean,
      default: true,
    },
    showSettingsButton:  {
      type: Boolean,
      default: true,
    },
    align: {
      type: String,
      default: 'start',
    }
  },
}
</script>
